/* eslint-disable react/no-unescaped-entities */
import Link from 'next/link';

import { isAluminatiSignInShow } from 'homePage/HomePage.utils';

import styles from './NotFoundPage.module.scss';

interface notFoundProps {
  domain: string;
}

export const NotFoundPage = ({ domain }: notFoundProps) => {
  const showAluminati = isAluminatiSignInShow(domain);

  return (
    <div className="fullColumn">
      <h1 className={styles.heading}>Oops! We can&apos;t find that page.</h1>

      <div>
        {showAluminati && (
          <>
            <div className={styles.text}>
              It looks like there isn't yet a clip associated with your email address. To receive your clip, please{' '}
              <Link href={`${process.env.NEXT_PUBLIC_ALUMINATI_SIGNIN_LINK}`} passHref>
                <a className={styles.link} target="_blank" rel="noopener noreferrer">
                  register here.
                </a>
              </Link>
            </div>
            <div className={styles.text}>
              You will receive an email when your clip is ready. Already registered?{' '}
              <Link href="https://stageclip.com/student-support/" passHref>
                <a className={styles.link} target="_blank" rel="noopener noreferrer">
                  Contact StageClip
                </a>
              </Link>{' '}
              for assistance.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

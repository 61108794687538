import { useQuery } from 'react-query';

import { fetchPortalViaProxy } from 'api/portal/fetchPortal';
import { NotFoundPage } from 'notFoundPage';
import { SiteLayout } from 'shared';

const Custom404 = () => {
  const { data } = useQuery('fetchPortal', () => fetchPortalViaProxy());
  const portalConfiguration = data?.data;

  return portalConfiguration ? (
    <SiteLayout
      clip={{}}
      logoImageUrl={portalConfiguration.logoImageUrl}
      logoBackgroundColor={portalConfiguration.logoBackgroundColor}
      color="#76a239"
      domain="test"
      clipSearchEnabled={true}
    >
      <NotFoundPage domain={portalConfiguration.domain} />
    </SiteLayout>
  ) : null;
};

export default Custom404;
